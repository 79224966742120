import { CellId } from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ExploreChatMode = "closed" | "low-power" | "history";

type MagicExploreState = {
  exploreChatMode: ExploreChatMode | null;
  cellId: CellId;
} | null;

/**
 * The Magic Explore slice is responsible for managing the state of the Magic Explore chat window, and any other
 * Magic requests that the user may make.
 *
 * For example - if a chart has too many data points - there is a CTA to 'Fix with Magic' which should then open
 * up the chat window with a prefilled prompt to fix the chart.
 */
export const magicExploreSlice = createSlice({
  name: "magicExplore",
  initialState: null as MagicExploreState,
  reducers: {
    initializeExploreChat: (
      _,
      action: PayloadAction<{ cellId: CellId; mode?: ExploreChatMode }>,
    ) => {
      return {
        exploreChatMode: action.payload.mode ?? "closed",
        cellId: action.payload.cellId,
      };
    },
    setExploreChatMode: (
      state,
      action: PayloadAction<{ cellId: CellId; mode: ExploreChatMode }>,
    ) => {
      return {
        exploreChatMode: action.payload.mode,
        cellId: action.payload.cellId ?? state?.cellId,
      };
    },
    toggleLowerPowerMode: (state) => {
      if (state == null) return null;
      return {
        exploreChatMode:
          state?.exploreChatMode === "low-power" ? "closed" : "low-power",
        cellId: state?.cellId,
      };
    },
  },
});

export const selectExploreChatMode = (state: {
  magicExplore: MagicExploreState;
}): ExploreChatMode | null => {
  return state?.magicExplore?.exploreChatMode ?? null;
};

export const magicExploreReducer = magicExploreSlice.reducer;

export const {
  initializeExploreChat,
  setExploreChatMode,
  toggleLowerPowerMode,
} = magicExploreSlice.actions;
